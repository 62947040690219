import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query stQuery {
      img0: file(relativePath: { eq: "st_header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img0cn: file(relativePath: { eq: "st_header_cn.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img0en: file(relativePath: { eq: "st_header_en.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img1: file(relativePath: { eq: "st_case.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO title="seo.title.SpeechPathology" description="seo.desc.nsp" />
      {locale === "zh-hk" && (
        <Img
          className="rounded mx-auto d-block pb-3 mt-3"
          fluid={data.img0.childImageSharp.fluid}
        />
      )}
      {locale === "zh-cn" && (
        <Img
          className="rounded mx-auto d-block pb-3 mt-3"
          fluid={data.img0cn.childImageSharp.fluid}
        />
      )}
      {locale === "en" && (
        <Img
          className="rounded mx-auto d-block pb-3 mt-3"
          fluid={data.img0en.childImageSharp.fluid}
        />
      )}
      <Container className="st-container">
        <Row className="st-row">
          <Col xl={6} lg={6} md={6} sm={6} xs={12} className="pb-3">
            <div className="st-content">
              {tt("pages.speech.part1.title")}
              <ul>
                <li>
                  <b className="title">
                    {tt("pages.speech.part1.point1.title")}
                  </b>
                  {tt("pages.speech.part1.point1.desc")}
                  <Link to="/#Syndromes">
                    {tt("pages.speech.part1.point1.descLink")}
                  </Link>
                  {tt("pages.speech.part1.point1.descEx")}
                  <br />
                  {tt("pages.speech.cause")}
                  {tt("pages.speech.part1.point1.cause")}
                </li>
                <li>
                  <b className="title">
                    {tt("pages.speech.part1.point2.title")}
                  </b>
                  {tt("pages.speech.part1.point2.desc")}
                  <Link to="/#Syndromes">
                    {tt("pages.speech.part1.point2.descLink")}
                  </Link>
                  {tt("pages.speech.part1.point2.descEx")}
                  <br />
                  {tt("pages.speech.cause")}
                  {tt("pages.speech.part1.point2.cause")}
                </li>
                <li>
                  <b className="title">
                    {tt("pages.speech.part1.point3.title")}
                  </b>
                  {tt("pages.speech.part1.point3.desc")}
                  <br />
                  {tt("pages.speech.cause")}
                  {tt("pages.speech.part1.point3.cause")}
                </li>
                <li>
                  <b className="title">
                    {tt("pages.speech.part1.point4.title")}
                  </b>
                  {tt("pages.speech.part1.point4.desc")}
                  <br />
                  {tt("pages.speech.cause")}
                  {tt("pages.speech.part1.point4.cause")}
                  <Link to="/puberphonia">
                    {tt("pages.speech.part1.point4.causeLink")}
                  </Link>
                  {tt("pages.speech.part1.point4.causeEx")}
                  <br />
                </li>
              </ul>
              {tt("pages.speech.part2.title")}
              <ul className="part2">
                <li>
                  <Link to="/aphasia">
                    {tt("pages.speech.part2.point1.title")}
                  </Link>
                  {tt("pages.speech.part2.point1.desc")}
                </li>
                <li>
                  <Link to="/aphasia">
                    {tt("pages.speech.part2.point2.title")}
                  </Link>
                  {tt("pages.speech.part2.point2.desc")}
                </li>
                <li>
                  <Link to="/aphasia">
                    {tt("pages.speech.part2.point3.title")}
                  </Link>
                  {tt("pages.speech.part2.point3.desc")}
                </li>
                <li>
                  <b className="title">
                    {tt("pages.speech.part2.point4.title")}
                  </b>
                  {tt("pages.speech.part2.point4.desc")}
                </li>
                <li>
                  <Link to="/swallowingdifficulty">
                    {tt("pages.speech.part2.point5.title")}
                  </Link>
                  {tt("pages.speech.part2.point5.desc")}
                </li>
              </ul>
            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={12}>
            <Img fluid={data.img1.childImageSharp.fluid} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ThePage
